import { NavLink } from "react-router-dom";
import logo from "./logo.png";

const Navbar = () => {
  return (
    <nav>
      <div>
        <img src={logo} alt="Logo" width="100px" height="100px" />
      </div>
      <div>
        <NavLink to="/" className="nav-links">
          Home
        </NavLink>
        <NavLink to="/team" className="nav-links">
          Team
        </NavLink>
        <NavLink to="/music-school" className="nav-links">
          Music School
        </NavLink>
        <NavLink to="/gallery" className="nav-links">
          Gallery
        </NavLink>
      </div>
    </nav>
  );
};

export default Navbar;
