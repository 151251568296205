import logo from "./images/logo.png";
const Footer = () => {
  return (
    <section id="footer-section">
      <footer>
        <div className="main-footer">
          <div>
            <img src={logo} alt="Church Logo" width="100" height="130px" />
            <p>
              <i class="fa fa-copyright" aria-hidden="true"></i> Copyright. All
              rights reserved.
            </p>
          </div>
          <div>
            <p>
              <i class="fa fa-home" aria-hidden="true"></i> 306, rue Notre-Dame,
              Gatineau, Quebec J8P1K8
            </p>
            <p>
              <i class="fa fa-envelope" aria-hidden="true"></i> Email:
              eglisenouvelleviegatineau@gmail.com
            </p>
            <p>
              <i class="fa fa-phone-square" aria-hidden="true"></i> Tel: (819) -
              329 - 1194
            </p>
            <p>
              <i class="fa fa-calendar-check-o" aria-hidden="true"></i> Office
              Hours: Monday-Friday 10: am - 5:00 pm
            </p>
          </div>
          <div>
            <p>
              <a
                href="https://tithe.ly/give_new/www/#/tithely/give-one-time/322636"
                target="_blank"
                rel="noreferrer"
              >
                <i class="fa fa-usd" aria-hidden="true"></i> Donate
              </a>
            </p>
            <p>
              <i class="fa fa-youtube-play" aria-hidden="true"></i> Youtube
            </p>
            <p>
              <i class="fa fa-facebook-official" aria-hidden="true"></i>{" "}
              Facebook
            </p>
            <p>
              <a href="#home-section">
                <i class="fa fa-chevron-up" aria-hidden="true"></i> Back to Top
              </a>
            </p>
          </div>
        </div>
        <div className="mobile-footer">
          <div>
            <p>
              <i class="fa fa-home" aria-hidden="true"></i> 306, rue Notre-Dame,
              Gatineau, Quebec J8P1K8
            </p>
            <p>
              <i class="fa fa-envelope" aria-hidden="true"></i> Email:
              eglisenouvelleviegatineau@gmail.com
            </p>
            <p>
              <i class="fa fa-phone-square" aria-hidden="true"></i> Tel: (819)
              329 1194
            </p>
            <p>
              <i class="fa fa-calendar-check-o" aria-hidden="true"></i> Office
              Hours: Monday-Friday 10: am - 5:00 pm
            </p>
          </div>
          <div>
            <p>
              <a
                href="https://tithe.ly/give_new/www/#/tithely/give-one-time/322636"
                target="_blank"
                rel="noreferrer"
              >
                <i class="fa fa-usd" aria-hidden="true"></i> Donate
              </a>
            </p>
            <p>
              <i class="fa fa-youtube-play" aria-hidden="true"></i> Youtube
            </p>
            <p>
              <i class="fa fa-facebook-official" aria-hidden="true"></i>{" "}
              Facebook
            </p>
            <p>
              <a href="#home-section">
                <i class="fa fa-chevron-up" aria-hidden="true"></i> Back to Top
              </a>
            </p>
          </div>
          <div>
            <img src={logo} alt="Church Logo" width="100" height="130px" />
            <p>
              <i class="fa fa-copyright" aria-hidden="true"></i> Copyright. All
              rights reserved.
            </p>
          </div>
        </div>
      </footer>
    </section>
  );
};

export default Footer;
