const Home = () => {
  return (
    <section className="home-section">
      <div className="home-grid">
        <div className="home-grid-item">
          <h1 className="home-title">Église Nouvelle Vie Gatineau</h1>
          <h2 className="home-subtitle">Bienvenue Frères et Sœurs</h2>
          <p className="home-info">
            Bienvenue sur le site officiel de l'Église Nouvelle Vie Gatineau.
            <br />
            Ce site est ici dans le but de vous informer de tout ce qui se passe
            à l'ENVG. <br />
            Welcome to the official website of Eglise Nouvelle Vie Gatineau.
            <br />
            This website is here for the purpose of informing you about
            everything happening at ENVG.
          </p>
        </div>
      </div>
    </section>
  );
};

export default Home;
